export const Colors =  {
    PRIMARY:{
        MAIN: "#4059CB",
        MAIN2:"#FF7900"
    },
    WHITE: {
        MAIN:"#F6F6F6"
    },
    BLACK:{
        MAIN:"#000000"
    },
    YELLOW:{
        MAIN:"#FFE779"
    }
}